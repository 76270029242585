<template>
  <div class="income">
    <van-nav-bar
      title="订单明细"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- 日期刷选 -->
    <div class="checkdate">
      <van-row>
        <van-col span="8">日期区间：</van-col>
        <van-col span="16">
          <van-row>
            <van-col span="10" @click="showStartDate">
              <div class="data_start">{{startDate}}</div>
            </van-col>
            <van-col span="4" style="color:#E66A6F;">至</van-col>
            <van-col span="10" @click="showEndDate">
              <div class="data_start">{{endDate}}</div>
            </van-col>
          </van-row>
        </van-col>
      </van-row>
    </div>
    <!-- 订单列表 -->
    <div class="main">
      <van-list
        v-model="currLoading"
        :finished="currFinished"
        finished-text="没有更多了"
        @load="onIncomeLoad"
      >
        <div v-if="list.length > 0">
          <div class="list" v-for="(item, index) in list" :key="index">
            <van-cell center class="userinfo">
              <template #icon>
                <div>
                  <van-image
                    width="20px"
                    height="20px"
                    lazy-load
                    round
                    fit="cover"
                    style="display: block;margin-right: 5px;"
                    :src="item.avatar ? item.avatar : 'https://dk.midbest.cn/img/head.ede6594c.png'"
                  >
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                </div>
              </template>
              <template #title>
                <div class="van-ellipsis">{{item.nickname ? item.nickname : '未设置昵称'}}</div>
              </template>
              <template #right-icon>
                <span style="margin-right: 10px;">{{item.ordersn}}</span>
                <van-button size="mini" @click.stop="copay(item.ordersn)">复制</van-button>
              </template>
              
            </van-cell>
            <van-card
              v-for="(val, key) in item.goods"
              :key="key"
              :desc="val.optionname"
              :thumb="val.goodsthumb"
              :title="val.goodstitle"
              :price="val.realprice"
              :num="val.total"
              lazy-load
            >
              <template #footer v-if="item.paytimeStr">
                <van-cell>
                  <template #title>
                    {{item.paytimeStr}}
                  </template>
                </van-cell>
              </template>
            </van-card>
            <div class="order_status">
              <van-cell>
                <template #icon>
                  <van-tag type="primary" round>{{item.status_str}}</van-tag>
                </template>
                <template #default>
                  实付金额 &yen;{{item.price}} 预估收益：<span>&yen;{{item.iconme}}</span>
                </template>
              </van-cell>

              <van-cell>
                <template #icon>
                  <span class="success_text" v-if="item.merchfakeapply == 1">已提现</span>
                  <span class="warning_text" v-if="item.merchfakeapply == 0">未提现</span>
                </template>
                <template #title>
                  <div class="order_total">
                    <span class="iconme" v-if="item.ydzg && item.iconme > 0">{{item.ydzg}}</span>
                    <span class="iconme">Y ({{item.agentcode1}})：&yen;{{item.commissions1_total}}</span>
                    <span class="iconme" v-if="item.commissions2 > 0">Y ({{item.agentcode2}})：&yen;{{item.commissions2_total}}</span>
                    <span class="iconme">C ({{item.shop_code}})：&yen;{{item.iconme}}</span>
                  </div>
                </template>
              </van-cell>

            </div>
          </div>
        </div>
      </van-list>
    </div>
      
    
    <div v-if="nodata">
      <van-empty description="暂无订单" />
    </div>
    <van-calendar v-model="dateShow" :min-date="minDate" :max-date="maxDate" :show-confirm="false" @confirm="onConfirm" />
    <!-- 加载中提示 -->
    <div id="loading" v-show="loadshow">
      <van-loading size="24px" type="spinner" vertical>加载中...</van-loading>
    </div>
  </div>
</template>
<script>
export default {
  name: "Income",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      currLoading: false,
      currFinished: false,
      nodata: false,
      loadshow: true,
      startDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-01", // 开始日期
      endDate: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date(new Date().getFullYear(), new Date().getMonth()+1, 0).getDate(), // 结束日期
      minDate: new Date(new Date().getFullYear()-1, new Date().getMonth(), 1), // 自定义最小可选择日期
      maxDate: new Date(new Date().getFullYear(), new Date().getMonth()+1, 0), // 自定义最大可选择日期
      currSize: 10,
      currOffset: 0,
      list: [],
      dateShow: false
    }
  },
  methods: {
    onIncomeLoad() {
      let that = null;
      that = this;
     that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/order/get_list",
          that.$qs.stringify({
            sTime: that.startDate,
            eTime: that.endDate,
            size: that.currSize,
            offset: that.currOffset
          })
        )
        .then(res => {
          console.log(res);
          that.currLoading = false;
          that.loadshow = false;
          if (res.data.code == 100000) {
            if (that.currOffset == 0) {
              that.list = res.data.data;
              that.orderlist = res.data.data;
            }else {
              if (res.data.data) {
                res.data.data.forEach(item => {
                  that.list.push(item);
                })
              }
              that.orderlist = that.list;
            }
            if (res.data.data.length >= that.currSize) {
              that.currOffset += that.currSize;
              that.currFinished = false;
            }else {
              that.currOffset += res.data.data.length;
              that.currFinished = true;
            }
          }else {
            that.currLoading = false;
            that.currFinished = true;
            if (that.currOffset == 0) {
              that.nodata = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
          that.currLoading = false;
          that.currFinished = true;
          if (that.currOffset == 0) {
            that.nodata = true;
          }
        })
    },
    // 开始时间
    showStartDate() {
      this.dateShowType = 1;
      this.dateShow = true;
    },
    // 结束时间
    showEndDate() {
      this.dateShowType = 2;
      this.dateShow = true;
    },
    // 选择好日期
    onConfirm(date) {
      this.dateShow = false;
      let year = null;
      let month = null;
      let day = null;
      year = date.getFullYear().toString();
      month = date.getMonth() + 1;
      day = date.getDate();
      day = parseInt(day) < 10 ? "0" + day.toString() : day.toString();
      month = parseInt(month) < 10 ? "0" + month.toString() : month.toString();
      if (this.dateShowType == 1) {
        this.startDate = year + "-" + month + "-" + day;
      } else {
        this.endDate = year + "-" + month + "-" + day;
        this.currOffset = 0;
        this.list = [];
        this.onIncomeLoad();
      }
      console.log(year, month, day);
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    }
  },
}
</script>
<style lang="less">
.income {
  width: 100%;
  overflow: hidden;
  min-height: 100%;
  background-color: #f0f0f0;
  &:last-child {
    padding-bottom: 50px;
  }
  /* 日期筛选样式 */
  .checkdate {
    padding: 0 10px;
    line-height: 44px;
    font-size: 14px;
    background-color: #ededed;
    .data_start {
      display: inline-block;
      margin: 0 auto;
      border-bottom: 2px solid #333;
    }
  }
  .main {
    overflow: hidden;
  }
  .list{
    background-color: #fff;
    overflow: hidden;
    margin: 10px;
    border-radius: 10px;
    text-align: left;
    padding-top: 10px;
    .van-cell {
      padding: 2px 16px;
      .van-cell__title {
        flex: 1;
        overflow: hidden;
        .order_total {
          width: 100%;
          text-align: right;
          .iconme {
            margin-left: 10px;
            color: #ee0a24;
            border: 1px solid #ee0a24;
            border-radius: 2px;
            padding: 1px 5px;
            font-size: 10px;
          }
        }
      }
      .success_text {
        color: #07C160;
      }
      .warning_text {
        color: #FF976A;
      }
    }
    .userinfo .van-cell__title {
      margin-right: 5px;
    }
    .van-card{
      background-color: #fff;
      padding: 10px;
      .van-card__thumb{
        img{
          border-radius: 4px;
        }
      }
      .van-card__footer{
        padding-top: 10px;
        text-align: left;
        .van-cell{
          padding: 0;
          color: #939393;
        }
      }
    }
    .van-card:not(:first-child){
      margin-top: 0;
    }
    .order_status{
      .van-cell{
        padding: 10px;
        .van-tag {
          padding: 0 10px
        }
        .van-cell__value{
          flex: 1;
          text-align: right;
        }
      }
    }
  }
  // 加载中
  #loading {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    .van-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -50px 0 0 -50px;
      padding: 20px;
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      .van-loading__text{
        color: #fff;
      }
    }
  }
}
</style>